// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--kOCrz";
export var infoWithImage = "styles-module--infoWithImage--nSiQI";
export var infoWithImageBackgroundGradient = "styles-module--infoWithImageBackgroundGradient--bAESw";
export var gradientImageWrapper = "styles-module--gradientImageWrapper--jms74";
export var image = "styles-module--image--kxHEu";
export var gradientImage = "styles-module--gradientImage--iNM3y";
export var gradientBg = "styles-module--gradientBg--z8udB";
export var text = "styles-module--text--jT50r";
export var infoWithImageBackgroundGray = "styles-module--infoWithImageBackgroundGray--iTqsW";
export var header = "styles-module--header--gBYdT";
export var heading = "styles-module--heading--VCgm+";
export var subheading = "styles-module--subheading--Qu36V";