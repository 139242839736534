import React from 'react';
import { graphql } from 'gatsby';
import Button from '@components/Button';
import { List, ListItem } from '@components/MarketingList';
import {
  PrismicHomepageDataBodyInfoWithImage,
  PrismicLinkTypeEnum,
  PrismicMarketingPageDataBodyInfoWithImage,
} from '@typings/prismic-types';
import BgGradient from '@images/bg-gradient-info-with-image.svg';
import * as styles from './styles.module.scss';

interface Props {
  slice:
    | PrismicHomepageDataBodyInfoWithImage
    | PrismicMarketingPageDataBodyInfoWithImage;
}

export default function InfoWithImage({ slice }: Props) {
  const {
    background,
    featured_image,
    heading,
    primary_link,
    primary_link_text,
    subheading,
    vertical_margin,
  } = slice.primary;
  const size = background === 'Gray' ? 'Default' : 'Small';

  return (
    <section
      className={`${styles.infoWithImage} ${
        background === 'Gray' ? `bgGray` : ``
      } ${
        background !== 'None'
          ? `${styles[`infoWithImageBackground${background}`]} hasPaddingBottom`
          : ``
      } full-width pad-${vertical_margin}`}
    >
      {background === 'Gradient' && (
        <div className={styles.gradientImageWrapper}>
          <div
            className={styles.gradientBg}
            style={{
              backgroundImage: `url("${BgGradient}")`,
            }}
          />
          <div
            style={{
              backgroundImage: `url("${featured_image.url}")`,
            }}
            className={`${styles.gradientImage} img-full-width-mobile`}
            aria-label={featured_image.alt}
          />
        </div>
      )}
      <div className={`${styles.image} img-full-width-mobile`}>
        <img
          width={`100%`}
          height={`100%`}
          src={featured_image ? featured_image.url : ''}
          alt={featured_image ? featured_image.alt : ''}
          aria-hidden={background === 'Gray'}
        />
      </div>
      <div className={styles.text}>
        <div className={styles.header}>
          <h2 className="marketing-h2 mb-14">{heading.text}</h2>
          <p
            className={`${styles.subheading} marketing-p-med-large mt-6 max-w-xl`}
          >
            {subheading.text}
          </p>
        </div>
        <div className="mb-10">
          <List size={size}>
            {slice.items.map((item, index: number) => (
              <ListItem
                key={`list-item-${styles.text}-${index}`}
                size={size}
                html={item.list_item}
              />
            ))}
          </List>
        </div>
        {primary_link && (
          <Button
            external={primary_link.link_type === PrismicLinkTypeEnum.Web}
            href={primary_link.url}
            useSearchParams
            size="Small"
            type="Primary"
            iconType="Arrow"
            className="mt-12"
          >
            {primary_link_text.text}
          </Button>
        )}
      </div>
    </section>
  );
}

export const query = graphql`
  fragment PrismicHomepageInfoWithImageFragment on PrismicHomepageDataBodyInfoWithImage {
    id
    slice_label
    slice_type
    primary {
      vertical_margin
      background
      featured_image {
        alt
        url
      }
      heading {
        html
        richText
        text
      }
      subheading {
        html
        richText
        text
      }
      primary_link {
        link_type
        url
        uid
      }
      primary_link_text {
        richText
      }
    }
    items {
      list_item
    }
  }
  fragment PrismicMarketingPageInfoWithImageFragment on PrismicMarketingPageDataBodyInfoWithImage {
    id
    slice_label
    slice_type
    primary {
      vertical_margin
      background
      featured_image {
        alt
        url
      }
      heading {
        html
        richText
        text
      }
      subheading {
        html
        richText
        text
      }
      primary_link {
        link_type
        url
        uid
      }
      primary_link_text {
        text
      }
    }
    items {
      list_item
    }
  }
`;
