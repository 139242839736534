import React from 'react';
import { PrismicMarketingPageDataBodyLargeTitle } from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicMarketingPageDataBodyLargeTitle;
}

const LargeTitle: React.ReactNode = ({ slice }: Props) => {
  return (
    <section className={`${styles.largeTitle}`}>
      <h1 className="text-6xl md:text-[88px]">{slice.primary.title.text}</h1>
    </section>
  );
};

export default LargeTitle;
