import React from 'react';
import CheckIcon from '@icons/check.svg';
import * as styles from './styles.module.scss';

interface Props {
  children?: React.ReactNode | string;
  size?: 'Default' | 'Small';
  html?: string;
}

const ListItem = ({ children, size = 'Default', html }: Props) => {
  return (
    <li
      className={`${styles.listItem} ${
        size !== 'Default' ? styles.listItemSmall : ``
      }`}
    >
      <span
        aria-hidden={true}
        className={`${styles.icon} ${
          size !== 'Default' ? styles.iconSmall : styles.iconDefault
        }`}
      >
        <CheckIcon />
      </span>
      {children || (
        <span
          dangerouslySetInnerHTML={{
            __html: html || ``,
          }}
        />
      )}
    </li>
  );
};

export default ListItem;
