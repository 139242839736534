import React from 'react';
import { graphql } from 'gatsby';
import WistiaVideoEmbed from '@components/WistiaVideoEmbed';
import {
  PrismicMarketingWistiaVideoAndTextSideBySideFragmentFragment,
  PrismicPartnerWistiaVideoAndTextSideBySideFragmentFragment,
} from '@typings/prismic-types';
import CheckIcon from '@icons/check-in-circle.svg';
import * as styles from './styles.module.scss';
import Background from '@components/Background';
import MarketingRichText from '@components/MarketingRichText/MarketingRichText';

interface Props {
  slice:
    | PrismicMarketingWistiaVideoAndTextSideBySideFragmentFragment
    | PrismicPartnerWistiaVideoAndTextSideBySideFragmentFragment;
}

export default function WistiaVideoAndTextSideBySide({ slice }: Props) {
  const {
    background,
    vertical_margin,
    gradient_image,
    mobile_gradient_image,
    wistia_video_url,
    title,
    description,
    is_video_on_right,
  } = slice.primary;

  const hasItems = !(
    slice.items.length === 1 &&
    slice.items[0].green_check_list_item.richText.length === 0
  );

  return (
    <Background
      background={background}
      margin={vertical_margin}
      gradientImageUrl={gradient_image?.url}
      mobileGradientImageUrl={mobile_gradient_image?.url}
    >
      <section className={styles.videoSideBySide}>
        <div className={styles.video}>
          <WistiaVideoEmbed videoUrl={wistia_video_url.url} />
        </div>
        <div className={styles.text}>
          <div className={styles.title}>
            <MarketingRichText render={title.richText} />
          </div>
          <div className={styles.description}>
            <MarketingRichText
              render={description.richText}
              className="marketing-p-med-large text-gray-700"
            />
          </div>
          {hasItems && (
            <div className={styles.list}>
              {slice.items.map((item, i) => (
                <div key={i}>
                  <div className={styles.listItem}>
                    <div className={styles.checkIcon}>
                      <CheckIcon />
                    </div>
                    <MarketingRichText
                      render={item.green_check_list_item?.richText}
                      className="marketing-p-med-large text-gray-700"
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </Background>
  );
}

export const query = graphql`
  fragment PrismicMarketingWistiaVideoAndTextSideBySideFragment on PrismicMarketingPageDataBodyWistiaVideoAndTextSideBySide {
    id
    slice_type
    slice_label
    primary {
      background
      vertical_margin
      gradient_image {
        url
      }
      mobile_gradient_image {
        url
      }
      wistia_video_url {
        link_type
        url
        uid
      }
      title {
        richText
      }
      description {
        richText
      }
      is_video_on_right
    }
    items {
      green_check_list_item {
        richText
      }
    }
  }
  fragment PrismicPartnerWistiaVideoAndTextSideBySideFragment on PrismicPartnerPageDataBodyWistiaVideoAndTextSideBySide {
    id
    slice_type
    slice_label
    primary {
      background
      vertical_margin
      gradient_image {
        url
      }
      mobile_gradient_image {
        url
      }
      wistia_video_url {
        link_type
        url
        uid
      }
      title {
        richText
      }
      description {
        richText
      }
      is_video_on_right
    }
    items {
      green_check_list_item {
        richText
      }
    }
  }
`;
