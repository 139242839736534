import React from 'react';
import {
  FullWidthImage,
  HomepageHero,
  InfoWithImage,
  CardsSection,
  CustomerLogos,
  CtaBanner,
  Faq,
  LargeTitle,
  MarketingPageHero,
  PricingCards,
  PricingGrid,
  StackedInfoWithImage,
  TabsEmbed,
  TestimonialCarousel,
  WistiaVideo,
  ImportantBlockQuote,
  DocsRichText,
  DocsFullWidthImage,
  BlogRichText,
  BlogImageWithCaption,
  BlogExtraWideImage,
  BlogExtraWideVideo,
  PricingFaq,
  SideBySideImages,
  Partner,
  WistiaVideoAndTextSideBySide,
  TextWithReferences,
  BlogTableOfContents,
} from '@components/slices';
import PricingCard from '@components/slices/PricingCard';
import {
  PrismicHomepageDataBodySlicesType,
  PrismicMarketingPageDataBodySlicesType,
  PrismicDocPageDataBodySlicesType,
  PrismicBlogPageDataBodySlicesType,
  PrismicPricingPageDataBodySlicesType,
  PrismicPartnerPageDataBodySlicesType,
  PrismicBlogPageFragmentFragment,
} from '@typings/prismic-types';

interface Props {
  slices: Array<
    | PrismicHomepageDataBodySlicesType
    | PrismicMarketingPageDataBodySlicesType
    | PrismicDocPageDataBodySlicesType
    | PrismicBlogPageDataBodySlicesType
    | PrismicPricingPageDataBodySlicesType
    | PrismicPartnerPageDataBodySlicesType
  >;
  blog?: PrismicBlogPageFragmentFragment;
}
// Use this type for all SliceZone components
interface SliceComponentsProps {
  [key: string]: React.ReactNode;
}

const sliceComponents: SliceComponentsProps = {
  homepage_hero: HomepageHero,
  full_width_image: FullWidthImage,
  info_with_image: InfoWithImage,
  cards_section: CardsSection,
  customer_logos: CustomerLogos,
  cta_banner: CtaBanner,
  faq: Faq,
  large_title: LargeTitle,
  marketing_page_hero: MarketingPageHero,
  pricing_cards: PricingCards,
  pricing_grid: PricingGrid,
  stacked_info_with_image: StackedInfoWithImage,
  tabs_embed: TabsEmbed,
  testimonial_carousel: TestimonialCarousel,
  wistia_video: WistiaVideo,
  important_block_quote: ImportantBlockQuote,
  docs_rich_text: DocsRichText,
  docs_full_width_image: DocsFullWidthImage,
  blog_rich_text: BlogRichText,
  blog_image_with_caption: BlogImageWithCaption,
  blog_extra_wide_image: BlogExtraWideImage,
  // blog_extra_wide_video: BlogExtraWideVideo,
  pricing_faq: PricingFaq,
  side_by_side_images: SideBySideImages,
  partner: Partner,
  wistia_video_and_text_side_by_side: WistiaVideoAndTextSideBySide,
  text_with_references: TextWithReferences,
  pricing_card: PricingCard,
  table_of_contents: BlogTableOfContents,
};

export default function SliceZone({ slices, blog }: Props) {
  return (
    <>
      {slices &&
        slices.map((slice, index: number) => {
          const SliceComponent = sliceComponents[slice.slice_type];
          if (!SliceComponent) return null;

          return (
            // @ts-ignore
            <SliceComponent key={`slice-${index}`} slice={slice} blog={blog} />
          );
        })}
    </>
  );
}
