import React from 'react';
import { PrismicDocPageDataBodyDocsFullWidthImage } from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicDocPageDataBodyDocsFullWidthImage;
}

export default function DocsFullWidthImage({ slice }: Props) {
  const { image, background_image_position: position } = slice.primary;
  return (
    <div className={`${styles.docsImage} ${styles[position]}`}>
      <img src={image.url} alt={image.alt} />
    </div>
  );
}
