// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--GL9pQ";
export var heading1 = "styles-module--heading1--GrIHZ";
export var heading2 = "styles-module--heading2--QfSoJ";
export var heading3 = "styles-module--heading3--DD1bG";
export var heading4 = "styles-module--heading4--VhLJ8";
export var heading5 = "styles-module--heading5--QPeoM";
export var heading6 = "styles-module--heading6--SmSgC";
export var small = "styles-module--small--anI2I";
export var paragraph = "styles-module--paragraph--EgkRr";
export var listItem = "styles-module--list-item--s9L09";
export var oListItem = "styles-module--o-list-item--+DAvv";
export var groupOListItem = "styles-module--group-o-list-item--DrlQF";
export var image = "styles-module--image--yhFLU";
export var embed = "styles-module--embed--ScTbv";
export var hyperlink = "styles-module--hyperlink--AxFYf";
export var headingLink = "styles-module--heading-link--e2Jff";
export var linkIcon = "styles-module--link-icon--bYxRe";