import React from 'react';
import Button from '@components/Button';
import InnerHtml from '@components/InnerHtml';
import { PrismicMarketingPageDataBodyPricingCards } from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicMarketingPageDataBodyPricingCards;
}

const PricingCards: React.ReactNode = ({ slice }: Props) => {
  return (
    <section className={`${styles.pricingCards}`}>
      {slice.items.map((item, index) => (
        <div key={`pricing-card-${index}`} className={styles.card}>
          <div>
            <h6>{item.heading.text}</h6>
            <p>
              <InnerHtml text={item.description.text} />
            </p>
            <div>
              <Button
                href={item.cta_link.url}
                type={'Primary'}
                iconType={'Arrow'}
              >
                {item.cta_label}
              </Button>
            </div>
          </div>
          <div className={styles.right}>
            <div>
              <span className={styles.monthly}>{item.monthly_payment}</span>
              <span>/mo</span>
            </div>
            <div>
              <span>or {item.yearly_payment}/yr</span>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default PricingCards;
