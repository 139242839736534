import { RichText } from 'prismic-reactjs';
import React from 'react';
import { graphql } from 'gatsby';
import Button from '@components/Button';
import { PricingFaqSliceFragment } from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';
import clsx from 'clsx';

interface Props {
  slice: PricingFaqSliceFragment;
}
export default function PricingFaq({ slice }: Props) {
  return (
    <section className={styles.faq}>
      <h2 className={styles.title}>{slice.primary.title.text}</h2>

      {slice.items.map((item) => (
        <div
          key={item.question.text}
          className={clsx(styles.item, 'max-w-3xl')}
        >
          <span className={styles.question}>{item.question.text}</span>
          <RichText render={item.answer.richText} />
        </div>
      ))}

      <Button
        className={styles.button}
        href={slice.primary.cta_link.url}
        iconType={slice.primary.cta_icon}
      >
        {slice.primary.cta_label.text}
      </Button>
    </section>
  );
}

export const query = graphql`
  fragment PricingFaqSlice on PrismicPricingPageDataBodyPricingFaq {
    id
    slice_type
    slice_label
    primary {
      title {
        text
      }
      cta_link {
        link_type
        url
        uid
      }
      cta_label {
        text
      }
      cta_icon
    }
    items {
      question {
        text
      }
      answer {
        richText
      }
    }
  }
`;
