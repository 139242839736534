import React from 'react';
import { Elements, RichText, RichTextSpan } from 'prismic-reactjs';
import {
  PrismicBlogPageDataBodyBlogRichText,
  PrismicBlogPageFragmentFragment,
} from '../../../graphql-types';
import textToIdString from '@utils/textToIdString';

interface SliceComponentsProps {
  [key: string]: React.ReactNode;
}

interface BlogTableOfContentsProps {
  blog: PrismicBlogPageFragmentFragment;
}
export default function BlogTableOfContents({
  blog,
}: BlogTableOfContentsProps) {
  const sliceComponents: SliceComponentsProps = {
    blog_rich_text: DocNavSlice,
  };

  return (
    <section className="my-0 mx-3 md:mx-auto md:w-[30rem] lg:w-[36rem]">
      <div className="border px-8 py-6 border-gray-300">
        <div className="mb-6 font-bold">Table of Contents</div>
        <ol className="list-decimal ml-4 text-gray-700 leading-5">
          {blog.data.body.map((slice, index) => {
            // @ts-ignore
            const SliceComponent = sliceComponents[slice.slice_type];
            if (!SliceComponent) {
              return null;
            }
            return (
              // @ts-ignore
              <SliceComponent key={`doc-nav-${index}`} slice={slice} />
            );
          })}
        </ol>
      </div>
    </section>
  );
}

interface DocNavSliceProps {
  slice: PrismicBlogPageDataBodyBlogRichText;
}

function DocNavSlice({ slice }: DocNavSliceProps) {
  const headers = slice.primary.content.richText.filter((span: RichTextSpan) =>
    [
      Elements.heading2,
      Elements.heading3,
      Elements.heading4,
      Elements.heading5,
    ].includes(span.type),
  );

  return <RichText render={headers} htmlSerializer={htmlSerializer} />;
}

function htmlSerializer<T>(
  type: Elements,
  element: any,
  content: string,
  children: T[],
  key: string,
) {
  switch (type) {
    case Elements.heading2:
    case Elements.heading3:
    case Elements.heading4:
    case Elements.heading5: {
      if (!element.text) return null;
      const id = textToIdString(element.text);
      return (
        <li key={id} className="mb-4 last:mb-0 pl-2">
          <a
            href={`#${id}`}
            onClick={(e) => {
              e.preventDefault();
              const el = document.getElementById(id);
              el.scrollIntoView({ behavior: 'smooth' });
            }}
            className="underline text-blue-600 saturate-50"
          >
            {element.text}
          </a>
        </li>
      );
    }

    default:
      // Always include a default that returns null
      return null;
  }
}
