// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--4C+6T";
export var list = "styles-module--list--kYcPi";
export var listDefault = "styles-module--listDefault--AoM0Z";
export var listSmall = "styles-module--listSmall--kcvCM";
export var listMarginSmall = "styles-module--listMarginSmall--Cwo16";
export var listItem = "styles-module--listItem--sy7mJ";
export var listItemSmall = "styles-module--listItemSmall--YW107";
export var icon = "styles-module--icon--S7nX7";
export var iconDefault = "styles-module--iconDefault--kl4F9";
export var iconSmall = "styles-module--iconSmall--JqUES";