import clsx from 'clsx';
import { RichText, RichTextProps } from 'prismic-reactjs';
import React from 'react';
import * as styles from './styles.module.scss';

interface Props extends RichTextProps {
  className?: string;
}
export default function MarketingRichText({ className, ...props }: Props) {
  return (
    <div className={clsx(styles.MarketingRichText, className)}>
      <RichText {...props} />
    </div>
  );
}
