import { RichText } from 'prismic-reactjs';
import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { graphql } from 'gatsby';
import { PrismicBlogPageDataBodySideBySideImages } from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicBlogPageDataBodySideBySideImages;
}

export default function SideBySideImages({ slice }: Props) {
  return (
    <section className={styles.section}>
      <div className={styles.images}>
        {slice.items.map((item, i) => (
          <div key={i} className={styles.imageContainer}>
            <Zoom>
              <img
                className={styles.image}
                src={item.image.url}
                alt={item.image.alt}
              />
            </Zoom>
          </div>
        ))}
      </div>
      <div className={styles.caption}>
        <RichText render={slice.primary.caption.richText} />
      </div>
    </section>
  );
}

export const query = graphql`
  fragment SideBySideImagesSlice on PrismicBlogPageDataBodySideBySideImages {
    id
    slice_type
    slice_label
    primary {
      caption {
        richText
      }
    }
    items {
      image {
        url
        alt
      }
    }
  }
`;
