import React from 'react';
import { CDN } from '@utils/constants';
import useScript from '@utils/hooks/useScript';
import useWindowSize from '@utils/hooks/useWindowSize';
import * as styles from './styles.module.scss';

interface Props {
  videoUrl: string;
}

const WistiaVideoEmbed = ({ videoUrl }: Props) => {
  // Load wistia video and library
  useScript(videoUrl);
  useScript(CDN.wistia);

  const { width } = useWindowSize();

  if (!videoUrl) {
    return null;
  }

  if (!/wistia\.com/.test(videoUrl)) {
    console.warn("WistiaVideoEmbed doesn't support non-Wistia video URLs");
    return null;
  }

  const wistiaId = videoUrl.split('medias/')[1].split('.jsonp')[0];
  const videoWidth = Math.min(800, width);
  return (
    <section className={`${styles.videoContainer} img-full-width-mobile`}>
      <span
        className={`wistia_embed wistia_async_${
          wistiaId || ``
        } popover=true popoverAnimateThumbnail=true preload=auto qualityMax=1080`}
        style={{
          display: `inline-block`,
          height: videoWidth / (16 / 9),
          position: `relative`,
          width: videoWidth,
        }}
      >
        &nbsp;
      </span>
    </section>
  );
};

export default WistiaVideoEmbed;
