// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--b-p4u";
export var richText = "styles-module--rich-text--kBtC8";
export var heading1 = "styles-module--heading1--eqNVp";
export var heading2 = "styles-module--heading2--TOSoM";
export var heading3 = "styles-module--heading3--9tlx5";
export var heading4 = "styles-module--heading4--HJo-W";
export var heading5 = "styles-module--heading5--ZWy9x";
export var heading6 = "styles-module--heading6--8G-rX";
export var small = "styles-module--small--VFtjD";
export var paragraph = "styles-module--paragraph--q6sCC";
export var listItem = "styles-module--list-item--3wFU+";
export var oListItem = "styles-module--o-list-item--41Ak9";
export var groupListItem = "styles-module--group-list-item--IygVI";
export var groupOListItem = "styles-module--group-o-list-item--YHxlE";
export var image = "styles-module--image--e7nSE";
export var embed = "styles-module--embed--aWTUO";
export var hyperlink = "styles-module--hyperlink--MfODC";
export var headingLink = "styles-module--heading-link--c0IcW";
export var linkIcon = "styles-module--link-icon--KlTaA";