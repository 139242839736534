import React from 'react';

interface Props {
  text?: string;
}

const InnerHtml = ({ text }: Props) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: `${text}`,
      }}
    />
  );
};

export default InnerHtml;
