import { Elements, RichText, Link as PrismicLink } from 'prismic-reactjs';
import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { Link } from 'gatsby';
import linkResolver from '@utils/linkResolver';
import textToIdString from '@utils/textToIdString';
import { PrismicBlogPageDataBodyBlogRichText } from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicBlogPageDataBodyBlogRichText;
}
export default function BlogRichText({ slice }: Props) {
  return (
    <RichText
      render={slice.primary.content.richText}
      htmlSerializer={htmlSerializer}
    />
  );
}

function htmlSerializer<T>(
  type: Elements,
  element: any,
  content: string,
  children: T[],
  key: string,
) {
  switch (type) {
    case Elements.heading1: {
      // Heading 1
      const id = textToIdString(element.text);
      return (
        <h1
          key={key}
          id={id}
          className={`${styles.richText} ${styles.heading1}`}
        >
          {children}
        </h1>
      );
    }

    case Elements.heading2: {
      // Heading 2
      const id = textToIdString(element.text);
      return (
        <h2
          key={key}
          id={id}
          className={`${styles.richText} ${styles.heading2}`}
        >
          {children}
        </h2>
      );
    }

    case Elements.heading3: {
      // Heading 3
      const id = textToIdString(element.text);
      return (
        <h3
          key={key}
          id={id}
          className={`${styles.richText} ${styles.heading3}`}
        >
          {children}
        </h3>
      );
    }

    case Elements.heading4: {
      // Heading 4
      const id = textToIdString(element.text);
      return (
        <h4
          key={key}
          id={id}
          className={`${styles.richText} ${styles.heading4}`}
        >
          {children}
        </h4>
      );
    }

    case Elements.heading5: {
      // Heading 5
      const id = textToIdString(element.text);
      return (
        <h5
          key={key}
          id={id}
          className={`${styles.richText} ${styles.heading5}`}
        >
          {children}
        </h5>
      );
    }

    case Elements.heading6: {
      // Heading 6
      return (
        <h6 key={key} className={`${styles.richText} ${styles.heading6}`}>
          {children}
        </h6>
      );
    }

    case Elements.paragraph: {
      // Paragraph
      return (
        <p key={key} className={`${styles.richText} ${styles.paragraph}`}>
          {children}
        </p>
      );
    }

    case Elements.preformatted: {
      // Preformatted
      return (
        <pre key={key} className={styles.richText}>
          {children}
        </pre>
      );
    }

    case Elements.strong: {
      // Strong
      return (
        <strong key={key} className={styles.richText}>
          {children}
        </strong>
      );
    }

    case Elements.em: {
      // Emphasis
      return (
        <em key={key} className={styles.richText}>
          {children}
        </em>
      );
    }

    case Elements.listItem: {
      // Unordered List Item
      return (
        <li key={key} className={`${styles.richText} ${styles.listItem}`}>
          {children}
        </li>
      );
    }

    case Elements.oListItem: {
      // Ordered List Item
      return (
        <li key={key} className={`${styles.richText} ${styles.oListItem}`}>
          {children}
        </li>
      );
    }

    case Elements.list: {
      // Unordered List
      return (
        <ul key={key} className={`${styles.richText} ${styles.groupListItem}`}>
          {children}
        </ul>
      );
    }

    case Elements.oList: {
      // Ordered List
      return (
        <ol key={key} className={`${styles.richText} ${styles.groupOListItem}`}>
          {children}
        </ol>
      );
    }

    case Elements.image: {
      // Image
      const linkUrl = element.linkTo
        ? element.linkTo.url || linkResolver(element.linkTo)
        : null;
      const target = element.linkTo?.target;
      const linkRel = target && 'noopener';
      return (
        <p key={key} className={`${styles.richText} ${styles.image}`}>
          {linkUrl ? (
            <a href={linkUrl} target={target} rel={linkRel}>
              {children}
            </a>
          ) : (
            <Zoom wrapElement={'span'}>
              <img src={element.url} alt={element.alt} />
            </Zoom>
          )}
        </p>
      );
    }
    case Elements.embed: {
      // Embed
      return (
        <div
          key={key}
          data-oembed={element.oembed.embed_url}
          data-oembed-type={element.oembed.type}
          data-oembed-provider={element.oembed.provider_name}
          className={`${styles.richText} ${styles.embed}`}
          // Style hack for full width embeds, derived from:
          // https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
          style={{
            paddingBottom: `${
              (element.oembed.height / element.oembed.width) * 100
            }%`,
          }}
          dangerouslySetInnerHTML={{ __html: element.oembed.html }}
        />
      );
    }

    case Elements.hyperlink: {
      // Hyperlinks
      const url = PrismicLink.url(element.data, linkResolver);
      if (element.data.link_type === 'Document') {
        return (
          <Link to={url} key={key} className={styles.hyperlink}>
            {content}
          </Link>
        );
      }
      const { target } = element.data;
      const linkRel = target && 'noopener';
      return (
        <a
          key={key}
          href={element.data.url || linkResolver(element.data)}
          target={target}
          rel={linkRel}
          className={styles.hyperlink}
        >
          {children}
        </a>
      );
    }

    case Elements.label: {
      // Label
      return (
        <span key={key} className={`${styles.richText} ${element.data?.label}`}>
          {children}
        </span>
      );
    }

    case Elements.span: {
      // Span
      if (!content) {
        return null;
      }
      return content.split('\\n').reduce((acc, p) => {
        if (acc.length === 0) {
          return [p];
        }
        const brIndex = (acc.length + 1) / 2 - 1;
        const br = <br key={brIndex} />;
        return acc.concat([br, p]);
      }, []);
    }

    default:
      // Always include a default that returns null
      return null;
  }
}
