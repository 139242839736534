import { RichText } from 'prismic-reactjs';
import React from 'react';
import { graphql } from 'gatsby';
import {
  PrismicHomepageDataBodyCustomerLogosPrimary,
  PrismicHomepageDataBodyCustomerLogosItem,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: {
    primary: PrismicHomepageDataBodyCustomerLogosPrimary;
    items: Array<PrismicHomepageDataBodyCustomerLogosItem>;
  };
}

export default function CustomerLogos({ slice }: Props) {
  const eyebrow = slice.primary.eyebrow_headline;
  const logos = slice.items;

  return (
    <section className={`${styles.customerLogos}`}>
      <h5 className={styles.eyebrow}>
        <RichText render={eyebrow.richText} />
      </h5>
      <div className={styles.logos}>
        {logos.map((item, index) => {
          return (
            <div key={`customer-logos-${index}`}>
              {item.link && item.link.url ? (
                <a href={item.link.url} target={`_blank`} rel={`noopener`}>
                  <img
                    height={28}
                    className={styles.logo}
                    src={item.logo.url}
                    alt={item.logo.alt}
                  />
                </a>
              ) : (
                <img
                  height={28}
                  className={styles.logo}
                  src={item.logo.url}
                  alt={item.logo.alt}
                />
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
}

export const query = graphql`
  fragment PrismicMarketingCustomerLogosFragment on PrismicMarketingPageDataBodyCustomerLogos {
    id
    slice_type
    slice_label
    primary {
      eyebrow_headline {
        html
        richText
        text
      }
    }
    items {
      logo {
        alt
        url
        dimensions {
          width
          height
        }
      }
      link {
        url
        type
        uid
      }
    }
  }
  fragment PrismicPartnerCustomerLogosFragment on PrismicPartnerPageDataBodyCustomerLogos {
    id
    slice_type
    slice_label
    primary {
      eyebrow_headline {
        html
        richText
        text
      }
    }
    items {
      logo {
        alt
        url
        dimensions {
          width
          height
        }
      }
      link {
        url
        type
        uid
      }
    }
  }
`;
