import React from 'react';
import Button from '@components/Button';
import InnerHtml from '@components/InnerHtml';
import { List, ListItem } from '@components/MarketingList';
import useWindowSize from '@utils/hooks/useWindowSize';
import {
  PrismicHomepageDataBodyFullWidthImage,
  PrismicMarketingPageDataBodyFullWidthImage,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';
import clsx from 'clsx';
import Background from '@components/Background';
import MarketingRichText from '@components/MarketingRichText/MarketingRichText';
import { graphql } from 'gatsby';

interface Props {
  slice:
    | PrismicHomepageDataBodyFullWidthImage
    | PrismicMarketingPageDataBodyFullWidthImage;
}

export default function FullWidthImage({ slice }: Props) {
  const windowSize = useWindowSize();
  const hasListItems = slice.items.length > 0 && !!slice.items[0].list_item;
  const {
    background,
    cta_label,
    cta_link,
    gradient_image,
    heading,
    image,
    mobile_gradient_image,
    mobile_image,
    subheading,
    subheading_position,
    vertical_margin,
  } = slice.primary;

  const Subheading = () => (
    <p className="marketing-p-med-large text-gray-700 mb-10">
      <MarketingRichText render={subheading.richText} />
    </p>
  );

  return (
    <Background
      background={background}
      margin={vertical_margin}
      gradientImageUrl={gradient_image?.url}
      mobileGradientImageUrl={mobile_gradient_image?.url}
    >
      <section className={styles.fullWidthImage}>
        <div className={styles.text}>
          <div className={clsx(styles.left, 'flex-1')}>
            <h2 className="marketing-h2">{heading.text}</h2>
            {subheading_position !== 'Right' && <Subheading />}
            {cta_link && cta_link.url && (
              <div className={styles.cta}>
                <Button type={'Primary'} iconType={'Arrow'} href={cta_link.url}>
                  {cta_label}
                </Button>
              </div>
            )}
          </div>
          <div className={clsx(styles.right, 'flex-1')}>
            {subheading_position === 'Right' && <Subheading />}
            {hasListItems && (
              <div className="mt-4">
                <List size={'Small'}>
                  {slice.items.map((item, index) => {
                    return (
                      <ListItem key={`fwi-list-item-${index}`} size={'Small'}>
                        {item.list_item}
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.image} img-full-width-mobile`}>
          {windowSize.isMobile && mobile_image && mobile_image.url ? (
            <img
              width={`100%`}
              height={`100%`}
              src={mobile_image.url}
              alt={mobile_image.alt}
            />
          ) : (
            <img
              width={`100%`}
              height={`100%`}
              src={image.url}
              alt={image.alt}
            />
          )}
        </div>
      </section>
    </Background>
  );
}

export const query = graphql`
  fragment FullWidthImageFragment on PrismicMarketingPageDataBodyFullWidthImage {
    id
    slice_type
    slice_label
    primary {
      vertical_margin
      background
      heading {
        html
        richText
        text
      }
      subheading {
        html
        richText
        text
      }
      subheading_position
      cta_link {
        url
        type
        uid
      }
      cta_label
      image {
        alt
        copyright
        url
        dimensions {
          height
          width
        }
      }
      gradient_image {
        alt
        copyright
        url
        dimensions {
          height
          width
        }
      }
      mobile_image {
        alt
        copyright
        url
        dimensions {
          height
          width
        }
      }
      mobile_gradient_image {
        alt
        copyright
        url
        dimensions {
          height
          width
        }
      }
    }
    items {
      list_item
    }
  }
`;
