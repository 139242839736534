// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--mSpxn";
export var partners = "styles-module--partners--D-kQZ";
export var main = "styles-module--main--FlsBo";
export var logos = "styles-module--logos--u0HxJ";
export var logo = "styles-module--logo--bOdrz";
export var plus = "styles-module--plus--Fvavm";
export var mainText = "styles-module--main-text--LYPdY";
export var expirationText = "styles-module--expiration-text--OtJik";
export var countdown = "styles-module--countdown--mepxf";
export var part = "styles-module--part--NUkx0";
export var title = "styles-module--title--4pTqZ";
export var value = "styles-module--value--fz2iQ";
export var separator = "styles-module--separator--ZYIkc";
export var card = "styles-module--card--39zIp";
export var customerText = "styles-module--customer-text--KY2gC";
export var cardTitle = "styles-module--card-title--KYwLw";
export var subtitle = "styles-module--subtitle--WEeGD";
export var buttons = "styles-module--buttons--1-14O";
export var primaryButton = "styles-module--primary-button--Jqvvm";
export var secondaryButton = "styles-module--secondary-button--hwnfh";