import { RichText } from 'prismic-reactjs';
import React from 'react';
import InnerHtml from '@components/InnerHtml';
import { List, ListItem } from '@components/MarketingList';
import {
  PrismicMarketingPageDataBodyStackedInfoWithImage,
  PrismicMarketingPageDataBodyStackedInfoWithImageItem,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';
import clsx from 'clsx';
import MarketingRichText from '@components/MarketingRichText/MarketingRichText';
import { graphql } from 'gatsby';

interface Props {
  slice: PrismicMarketingPageDataBodyStackedInfoWithImage;
}
export default function StackedInfoWithImage({ slice }: Props) {
  const { background, description, heading, vertical_margin } = slice.primary;
  const hasHeading = !!(heading.text || description.html);

  return (
    <section
      className={`${styles.stackedInfoWithImage} ${
        background === `Gray` ? `bgGray` : ``
      } full-width pad-${vertical_margin}`}
    >
      {hasHeading && (
        <div>
          {heading.text && <h2 className="marketing-h2">{heading.text}</h2>}
          {description.html && (
            <div
              className={`${styles.description} marketing-p-med-large text-gray-700`}
            >
              <InnerHtml text={description.html} />
            </div>
          )}
        </div>
      )}
      {slice.items.map((item, index: number) => {
        return <InfoWIthImageItem item={item} key={`stacked-${index}`} />;
      })}
    </section>
  );
}

interface InfoWIthImageItemProps {
  item: PrismicMarketingPageDataBodyStackedInfoWithImageItem;
}
function InfoWIthImageItem({ item }: InfoWIthImageItemProps) {
  const size = 'Small';
  const {
    featured_image,
    heading,
    subheading,
    list_item_1,
    list_item_2,
    list_item_3,
  } = item;

  return (
    <article className="flex flex-col md:flex-row">
      <div className="mb-6 mt-10 md:flex-[4] md:my-0 md:mr-28">
        <h4 className={clsx(styles.heading, 'marketing-large-p')}>
          {heading.text}
        </h4>
        <MarketingRichText
          render={subheading.richText}
          className="mb-6 md:mb-10 text-gray-700 marketing-p"
        />
        {list_item_1 && (
          <List marginType={'Small'} size={size}>
            <ListItem size={size} html={list_item_1} />
            {list_item_2 && <ListItem size={size} html={list_item_2} />}
            {list_item_3 && <ListItem size={size} html={list_item_3} />}
          </List>
        )}
      </div>
      <div className="md:flex-[7]">
        {featured_image.url && (
          <div className={`${styles.image} img-full-width-mobile`}>
            <img src={featured_image.url} alt={featured_image.alt} />
          </div>
        )}
      </div>
    </article>
  );
}

export const query = graphql`
  fragment StackedInfoWithImageFragment on PrismicMarketingPageDataBodyStackedInfoWithImage {
    id
    slice_type
    slice_label
    primary {
      vertical_margin
      background
      heading {
        text
      }
      description {
        richText
        html
        text
      }
    }
    items {
      featured_image {
        url
        alt
      }
      heading {
        text
      }
      subheading {
        text
        richText
      }
      list_item_1
      list_item_2
      list_item_3
    }
  }
`;
