import React from 'react';
import Button from '@components/Button';
import InnerHtml from '@components/InnerHtml';
import { PrismicMarketingPageDataBodyFaq } from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicMarketingPageDataBodyFaq;
}

export default function Faq({ slice }: Props) {
  const cta = slice.primary.cta_link && slice.primary.cta_link.url;
  return (
    <section className={`pad-Both`}>
      <div className="flex justify-between mb-8 md:mb-20">
        <h2 className="marketing-h2">{slice.primary.heading.text}</h2>
        {cta && (
          <Button href={cta} type={'Primary'} iconType={'External'}>
            {slice.primary.cta_label}
          </Button>
        )}
      </div>
      <div>
        {slice.items.map((item, index) => (
          <div className="max-w-3xl mb-10 md:mb-20" key={`faq-${index}`}>
            <h4 className="marketing-h4 mb-4">
              <InnerHtml text={item.question.text} />
            </h4>
            <p
              className={
                'text-[1.5rem] leading-[1.75rem] tracking-[0.01em] text-gray-500'
              }
            >
              <InnerHtml text={item.answer.text} />
            </p>
          </div>
        ))}
      </div>
    </section>
  );
}
