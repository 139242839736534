import { RichText } from 'prismic-reactjs';
import React from 'react';
import { graphql } from 'gatsby';
import Button from '@components/Button';
import { MarketingPricingCardFragmentFragment } from '@typings/prismic-types';
import Background from '@components/Background';

interface Props {
  slice: MarketingPricingCardFragmentFragment;
}

export default function PricingCard({ slice }: Props) {
  const {
    background,
    vertical_margin,
    gradient_image,
    mobile_gradient_image,
    heading,
    description,
    button_text,
    button_link,
    more_text,
    card_heading,
    card_price_per_month,
    card_price_subtitle,
    card_button_text,
    card_button_link,
  } = slice.primary;

  return (
    <Background
      background={background}
      margin={vertical_margin}
      gradientImageUrl={gradient_image?.url}
      mobileGradientImageUrl={mobile_gradient_image?.url}
    >
      <section className="relative no-layout-margin bg-[#f4f7f9] py-10 smPlus:py-32 overflow-hidden">
        <div className="absolute top-0 right-0 w-72 h-72 bg-[#e54c4c] rounded-full mix-blend-normal filter blur-3xl opacity-70 animate-blob"></div>
        <div className="absolute top-10 right-32 w-40 h-40 bg-[#5c5ce5] rounded-full mix-blend-normal filter blur-3xl opacity-70 animate-blob"></div>
        <div className="absolute top-44 right-10 w-52 h-52 bg-[#3d7dff] rounded-full mix-blend-normal filter blur-3xl opacity-70 animate-blob"></div>
        <div className="layout-margin grid smPlus:grid-cols-2 lg:grid-cols-3 gap-16 xl:gap-36">
          <div className="order-2 smPlus:order-1 col-span-1 lg:col-span-2">
            <h2 className="marketing-h2">{heading.text}</h2>
            <div className="my-6">
              <p className="marketing-p-med-large">{description.text}</p>
            </div>
            <div className="my-14">
              <Button href={button_link.url} iconType="Arrow">
                {button_text.text}
              </Button>
            </div>
            <RichText render={more_text.richText} />
          </div>
          <div className="relative order-1 smPlus:order-2 col-span-1">
            <div className="absolute -bottom-4 inset-x-0 h-full bg-white rounded-md scale-90 origin-bottom shadow-[0px_16px_40px_0px_rgba(20,32,38,0.15)]" />
            <div className="absolute -bottom-2 inset-x-0 h-full bg-white rounded-md scale-95 origin-bottom shadow-[0px_16px_40px_0px_rgba(20,32,38,0.1)]" />
            <div className="absolute bottom-0 inset-x-0 h-full bg-white flex flex-col p-10 rounded-md shadow-[0px_16px_40px_0px_rgba(20,32,38,0.05)]">
              <div className="flex flex-col space-y-2">
                <h3 className="text-black text-xl font-semibold">
                  {card_heading.text}
                </h3>
                <div className="text-black font-semibold">
                  <span className="text-3xl">{`$${card_price_per_month}`}</span>
                  <span className="text-xl">/mo</span>
                </div>
                <div className="text-gray-900 text-xs tracking-wider">
                  {card_price_subtitle.text}
                </div>
              </div>
              <Button
                className="my-6"
                type="Secondary"
                size="Small"
                iconType="Arrow"
                href={card_button_link.url}
              >
                {card_button_text.text}
              </Button>
              <ul className="flex flex-col space-y-2 text-gray-900 text-xs tracking-tight">
                <li className="font-semibold">Recommended For:</li>
                {slice.items.map((item) => (
                  <li
                    key={item.card_recommended_for.text}
                    className="tracking-tight"
                  >
                    {item.card_recommended_for.text}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Background>
  );
}

export const query = graphql`
  fragment MarketingPricingCardFragment on PrismicMarketingPageDataBodyPricingCard {
    id
    slice_type
    slice_label
    primary {
      background
      vertical_margin
      gradient_image {
        url
      }
      mobile_gradient_image {
        url
      }
      heading {
        richText
        text
      }
      description {
        richText
        text
      }
      button_text {
        text
      }
      button_link {
        link_type
        url
        uid
      }
      more_text {
        richText
      }
      card_heading {
        richText
        text
      }
      card_price_per_month
      card_price_subtitle {
        richText
        text
      }
      card_button_text {
        text
      }
      card_button_link {
        link_type
        url
        uid
      }
    }
    items {
      card_recommended_for {
        text
      }
    }
  }
`;
