/**
 * NOTE: This is called "Configurable Grid" in Prismic.
 * Cannot rename the id 'cards_section' because it will remove existing content.
 */
import React from 'react';
import { graphql } from 'gatsby';
import Button from '@components/Button';
import Link from '@components/Link';
import {
  PrismicHomepageDataBodyCardsSection,
  PrismicHomepageDataBodyCardsSectionItem,
  PrismicMarketingPageDataBodyCardsSection,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';
import clsx from 'clsx';

type PrismicImage = { url?: string; alt?: string };

interface Props {
  slice:
    | PrismicHomepageDataBodyCardsSection
    | PrismicMarketingPageDataBodyCardsSection;
}

interface CardProps extends PrismicHomepageDataBodyCardsSectionItem {
  isTextAbove?: boolean;
  card_image?: PrismicImage;
}

const CardImage = ({ card_image }: { card_image: PrismicImage }) => (
  <img
    width={`100%`}
    height={`100%`}
    src={card_image.url}
    alt={card_image.alt}
  />
);

const Card = ({
  card_image,
  card_heading,
  card_body,
  card_link,
  card_link_label,
  isTextAbove,
}: CardProps) => {
  const CardHeading = () => (
    <h5
      className={clsx(
        'marketing-h3-medium',
        styles.cardHeading,
        isTextAbove && "marketing-large-p font-semibold",
      )}
    >
      {card_heading}
    </h5>
  );

  const CardHeadingWrapper = () => {
    if (card_link && card_link.url) {
      return (
        <Link href={card_link.url}>
          <CardHeading />
        </Link>
      );
    }

    return <CardHeading />;
  };

  const CardText = () => (
    <div
      className={`${isTextAbove ? styles.cardTextAbove : styles.cardTextBelow}`}
    >
      <div>
        <CardHeadingWrapper />
        <p className={clsx(styles.cardBody, 'marketing-p')}>{card_body}</p>
      </div>
      {card_link && card_link.url && (
        <div className={clsx(styles.cardLink, "mt-6")}>
          <Link iconType={'Arrow'} href={card_link.url}>
            {card_link_label || `Learn More`}
          </Link>
        </div>
      )}
    </div>
  );

  return (
    <article
      className={`${styles.card} ${isTextAbove ? styles.cardAbove : ``}`}
    >
      {isTextAbove && <CardText />}
      <div className={'img-full-width-mobile'}>
        {card_link && card_link.url ? (
          <Link href={card_link.url}>
            <CardImage card_image={card_image} />
          </Link>
        ) : (
          <CardImage card_image={card_image} />
        )}
      </div>
      {!isTextAbove && <CardText />}
    </article>
  );
};

const CardsSection = ({ slice }: Props) => {
  const headline = slice.primary.headline.text;
  const description = slice.primary.description
    ? slice.primary.description.text
    : null;
  const cards = slice.items;
  const bg = slice.primary.background;
  const numberOfColumns = slice.primary.number_of_columns;

  let classNames = `pad-${slice.primary.vertical_margin}`;
  if (
    slice.primary.headline_position !== 'Top' &&
    styles[`cardsSectionPosition${slice.primary.headline_position}`]
  ) {
    classNames += ` ${
      styles[`cardsSectionPosition${slice.primary.headline_position}`]
    }`;
  }
  if (
    slice.primary.card_text_position &&
    styles[`cardsTextPosition${slice.primary.card_text_position}`]
  ) {
    classNames += ` ${
      styles[`cardsTextPosition${slice.primary.card_text_position}`]
    }`;
  }
  if (bg !== 'None') {
    classNames += ` full-width`;
    if (bg === 'Gray') {
      classNames += ` bgGray`;
    }
  }

  return (
    <section className={`${classNames}`}>
      <div className={styles.header}>
        <div className={styles.headline}>
          <h2 className="marketing-h2 mb-6">{headline}</h2>
          {description && (
            <p className="marketing-p-med-large text-gray-700">{description}</p>
          )}
        </div>
        <div className={styles.buttons}>
          {slice.primary.primary_cta_link && (
            <Button
              href={slice.primary.primary_cta_link.url}
              type={'Primary'}
              iconType={'Arrow'}
            >
              {slice.primary.primary_cta_label || ``}
            </Button>
          )}
          {slice.primary.secondary_cta_link && (
            <Button
              iconType={'Video'}
              iconPosition={'Left'}
              type={'Secondary'}
              wistiaUrl={slice.primary.secondary_cta_link.url}
            >
              {slice.primary.secondary_cta_label || ``}
            </Button>
          )}
        </div>
      </div>
      <div
        className={`${styles.cards} ${
          styles[`cardsColumns${numberOfColumns}`]
        }`}
      >
        {cards.map((card, index) => {
          return (
            <Card
              key={`card-${index}`}
              card_image={card.card_image}
              card_heading={card.card_heading}
              card_body={card.card_body}
              card_link={card.card_link}
              card_link_label={card.card_link_label}
              isTextAbove={slice.primary.card_text_position === 'Above'}
            />
          );
        })}
      </div>
    </section>
  );
};

export default CardsSection;

export const query = graphql`
  fragment PrismicMarketingCardsSectionFragment on PrismicMarketingPageDataBodyCardsSection {
    id
    slice_type
    slice_label
    primary {
      vertical_margin
      headline {
        html
        richText
        text
      }
      description {
        html
        richText
        text
      }
      background
      headline_position
      number_of_columns
      card_text_position
      primary_cta_link {
        url
        type
        uid
      }
      primary_cta_label
      secondary_cta_link {
        url
        type
        uid
      }
      secondary_cta_label
    }
    items {
      card_image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      card_heading
      card_body
      card_link {
        url
        type
        uid
      }
      card_link_label
    }
  }
  fragment PrismicPartnerCardsSectionFragment on PrismicPartnerPageDataBodyCardsSection {
    id
    slice_type
    slice_label
    primary {
      vertical_margin
      headline {
        html
        richText
        text
      }
      description {
        html
        richText
        text
      }
      background
      headline_position
      number_of_columns
      card_text_position
      primary_cta_link {
        url
        type
        uid
      }
      primary_cta_label
      secondary_cta_link {
        url
        type
        uid
      }
      secondary_cta_label
    }
    items {
      card_image {
        alt
        url
        dimensions {
          width
          height
        }
      }
      card_heading
      card_body
      card_link {
        url
        type
        uid
      }
      card_link_label
    }
  }
`;
