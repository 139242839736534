import React from 'react';
import * as styles from './styles.module.scss';

interface Props {
  children?: React.ReactNode;
  size?: 'Default' | 'Small';
  marginType?: 'Default' | 'Small';
}

const List = ({
  children,
  size = 'Default',
  marginType = 'Default',
}: Props) => {
  return (
    <ul
      className={`${styles.list} ${
        size !== 'Default' ? styles.listSmall : styles.listDefault
      } ${marginType !== 'Default' ? styles.listMarginSmall : ``}`}
    >
      {children}
    </ul>
  );
};

export default List;
