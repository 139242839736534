// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--in6z6";
export var cardsTextPositionAbove = "styles-module--cardsTextPositionAbove--IYqMZ";
export var cardsSectionPositionLeft = "styles-module--cardsSectionPositionLeft--kvWgn";
export var header = "styles-module--header--N9yZ5";
export var card = "styles-module--card--gygpm";
export var cards = "styles-module--cards--gevwa";
export var cardsColumns2 = "styles-module--cardsColumns2--I8FJH";
export var cardsColumns3 = "styles-module--cardsColumns3--JzcNY";
export var headline = "styles-module--headline--m6dZp";
export var cardAbove = "styles-module--cardAbove--DRT5u";
export var cardTextAbove = "styles-module--cardTextAbove--0che5";
export var cardTextBelow = "styles-module--cardTextBelow--c+q2F";
export var cardBody = "styles-module--cardBody--ByfB5";
export var cardLink = "styles-module--cardLink--ibutX";
export var cardHeading = "styles-module--cardHeading--1vhBB";
export var buttons = "styles-module--buttons--mQTI5";