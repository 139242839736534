import React from 'react';
import { PrismicMarketingPageDataBodyMarketingPageHero } from '@typings/prismic-types';

interface Props {
  slice: PrismicMarketingPageDataBodyMarketingPageHero;
}

export default function MarketingPageHero({ slice }: Props) {
  return (
    <section className="md:grid grid-cols-12 my-10 md:my-20">
      <div className="col-span-12">
        <h6 className="marketing-small-p-bold text-gray-600">
          {slice.primary.eyebrow.text}
        </h6>
      </div>
      <div className="col-span-6">
        <div className="my-6 md:my-14">
          <h1 className="marketing-h1-small">{slice.primary.heading.text}</h1>
        </div>
        <h4 className="marketing-xlarge-p text-gray-700">
          {slice.primary.subheading.text}
        </h4>
      </div>
      <div className="col-span-6 scale-150 translate-x-1/3 translate-y-1/3">
        <img
          width={`100%`}
          height={`800px`}
          src={slice.primary.image.url}
          alt={slice.primary.image.alt}
        />
      </div>
    </section>
  );
}
