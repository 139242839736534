import React from 'react';
import WistiaVideoEmbed from '@components/WistiaVideoEmbed';
import { PrismicHomepageDataBodyWistiaVideo } from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicHomepageDataBodyWistiaVideo;
}

const WistiaVideo = ({ slice }: Props) => {
  const videoUrl = slice.primary.video_link.url;
  return (
    <>
      <WistiaVideoEmbed videoUrl={videoUrl} />
    </>
  );
};

export default WistiaVideo;
