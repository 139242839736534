import React from 'react';
import { PrismicDocPageDataBodyImportantBlockQuote } from '@utils/typings/prismic-types';
import ExclamationIcon from '../../../static/icons/exclamation-mark.svg';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicDocPageDataBodyImportantBlockQuote;
}

const ImportantBlockQuote: React.ReactNode = ({ slice }: Props) => {
  const { heading, text } = slice.primary;

  return (
    <blockquote className={styles.blockquote}>
      <div className={styles.header}>
        <ExclamationIcon />
        <div className={styles.title}>{heading.text}</div>
      </div>
      <div className={styles.text}>{text.text}</div>
    </blockquote>
  );
};

export default ImportantBlockQuote;
