import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { PrismicBlogPageDataBodyBlogImageWithCaption } from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicBlogPageDataBodyBlogImageWithCaption;
}

export default function BlogImageWithCaption({ slice }: Props) {
  const { image, caption } = slice.primary;
  return (
    <figure className={styles.figure}>
      <div className={styles.image}>
        <Zoom wrapElement={'span'}>
          <img src={image.url} alt={image.alt} />
        </Zoom>
        {caption.text && (
          <figcaption className={styles.caption}>{caption.text}</figcaption>
        )}
      </div>
    </figure>
  );
}
