import React from 'react';
import { graphql } from 'gatsby';
import InnerHtml from '@components/InnerHtml';
import {
  PricingGridMarketingSliceFragment,
  PricingGridPricingSliceFragment,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';
import clsx from 'clsx';

interface Props {
  slice: PricingGridPricingSliceFragment | PricingGridMarketingSliceFragment;
}
export default function PricingGrid({ slice }: Props) {
  const headline = slice.primary.headline.text;
  return (
    <section
      className={`full-width bg${slice.primary.background} pad-${slice.primary.vertical_margin}`}
    >
      <div className={styles.header}>
        <h2 className='marketing-h2'>{headline}</h2>
      </div>
      <div className={styles.grid}>
        {slice.items.map((item, index) => {
          return (
            <GridItem
              key={`card-${index}`}
              image={item.image}
              heading={item.heading}
              body={item.body}
            />
          );
        })}
      </div>
    </section>
  );
}

function GridItem({
  image,
  heading,
  body,
}:
  | PricingGridPricingSliceFragment['items'][number]
  | PricingGridMarketingSliceFragment['items'][number]) {
  return (
    <article>
      <div>
        <img
          width={64}
          height={64}
          className={styles.image}
          src={image.url}
          alt={image.alt}
        />
        <h6 className={clsx(styles.heading, "font-medium text-[1.25rem] leading-6")}>
          {heading.text}
          {` `}
          <span className={styles.body}>
            <InnerHtml text={body.text} />
          </span>
        </h6>
      </div>
    </article>
  );
}

// Need 2 fragments because they are considered "separate" and cannot be combined.
// Make sure to update both. Since it's a template literal, we can't even extract the
// fragment to a single place.
export const query = graphql`
  fragment PricingGridPricingSlice on PrismicPricingPageDataBodyPricingGrid {
    id
    slice_type
    slice_label
    primary {
      background
      headline {
        text
      }
      vertical_margin
    }
    items {
      body {
        text
      }
      heading {
        text
      }
      image {
        url
        alt
      }
    }
  }
  fragment PricingGridMarketingSlice on PrismicMarketingPageDataBodyPricingGrid {
    id
    slice_type
    slice_label
    primary {
      background
      headline {
        text
      }
      vertical_margin
    }
    items {
      body {
        text
      }
      heading {
        text
      }
      image {
        url
        alt
      }
    }
  }
`;
