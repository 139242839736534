// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--qeHLB";
export var testimonialCarousel = "styles-module--testimonialCarousel--MpCNf";
export var carouselTop = "styles-module--carouselTop--SwHtp";
export var buttons = "styles-module--buttons--0mXex";
export var heading = "styles-module--heading--11Jgu";
export var slide = "styles-module--slide--1rS2q";
export var slideActive = "styles-module--slideActive--p1488";
export var text = "styles-module--text--7Enef";
export var quote = "styles-module--quote--PPtnY";
export var image = "styles-module--image--+GkUI";
export var buttonLeft = "styles-module--buttonLeft--BnRax";
export var buttonRight = "styles-module--buttonRight--XvfRQ";
export var footnote = "styles-module--footnote--qUcM9";