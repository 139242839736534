import { RichText } from 'prismic-reactjs';
import React from 'react';
import { graphql } from 'gatsby';
import {
  PrismicGrowthCalculatorDataBodyTextWithReferences,
  PrismicLtvCalculatorDataBodyTextWithReferences,
} from '@typings/prismic-types';

interface Props {
  slice:
    | PrismicGrowthCalculatorDataBodyTextWithReferences
    | PrismicLtvCalculatorDataBodyTextWithReferences;
}

export default function TextWithReferences({ slice }: Props) {
  const hasReferences = slice.items[0].references.richText.length > 0;
  return (
    <section className="my-24 mx-auto grid grid-cols-3 gap-36 prose xl:prose-xl max-w-5xl">
      <div className="col-span-2 ">
        <RichText render={slice.primary.text.richText} />
      </div>
      {hasReferences && (
        <div className="col-span-1 self-center">
          <span className="not-prose text-xxs text-gray-600 tracking-widest">
            References
          </span>
          <ul>
            {slice.items.map((item, index) => (
              <li
                key={index}
                className="prose-sm text-sm text-black leading-6 hover:underline"
              >
                <RichText key={index} render={item.references.richText} />
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  );
}

export const query = graphql`
  fragment TextWithReferencesSlice on PrismicGrowthCalculatorDataBodyTextWithReferences {
    id
    slice_type
    slice_label
    primary {
      text {
        richText
      }
    }
    items {
      references {
        richText
      }
    }
  }
  fragment TextWithReferencesLtvSlice on PrismicLtvCalculatorDataBodyTextWithReferences {
    id
    slice_type
    slice_label
    primary {
      text {
        richText
      }
    }
    items {
      references {
        richText
      }
    }
  }
`;
