import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { PrismicBlogPageDataBodyBlogExtraWideImage } from '@utils/typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicBlogPageDataBodyBlogExtraWideImage;
}

export default function BlogExtraWideImage({ slice }: Props) {
  const { image, caption } = slice.primary;
  return (
    <figure className={styles.figure}>
      <div className={styles.image}>
        <Zoom wrapElement={'span'}>
          <img src={image.url} alt={image.alt} />
        </Zoom>
      </div>
      {caption.text && (
        <figcaption className={styles.caption}>{caption.text}</figcaption>
      )}
    </figure>
  );
}
