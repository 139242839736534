import useWindowSize from '@utils/hooks/useWindowSize';
import clsx from 'clsx';
import React from 'react';

interface Props {
  margin?: string;
  background?: string;
  gradientImageUrl?: string;
  mobileGradientImageUrl?: string;
  children?: React.ReactNode;
}
export default function Background({
  margin,
  background,
  gradientImageUrl,
  mobileGradientImageUrl,
  children,
}: Props) {
  const gradient = useGradient(gradientImageUrl, mobileGradientImageUrl);

  return (
    <div
      className={clsx(
        'full-width',
        `bg${background}`,
        `pad-${margin}`,
        gradient && 'hasPaddingBottom',
      )}
      style={
        gradient && {
          backgroundImage: `url(${gradient})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'none',
        }
      }
    >
      {children}
    </div>
  );
}

function useGradient(gradientImageUrl: string, mobileGradientImageUrl: string) {
  const windowSize = useWindowSize();

  let gradient: string | null = null;
  if (windowSize.isMobile) {
    gradient = mobileGradientImageUrl;
  }
  if (!gradient) {
    gradient = gradientImageUrl;
  }
  return gradient;
}
