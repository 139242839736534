import clsx from 'clsx';
import React from 'react';
import { graphql } from 'gatsby';
import Button from '@components/Button';
import {
  PrismicHomepageTestimonialCarouselFragmentFragment,
  PrismicMarketingTestimonialCarouselFragmentFragment,
} from '@typings/prismic-types';
import ArrowLeftIcon from '@icons/arrow-left-carousel.svg';
import * as styles from './styles.module.scss';

interface Props {
  slice:
    | PrismicMarketingTestimonialCarouselFragmentFragment
    | PrismicHomepageTestimonialCarouselFragmentFragment;
}

export default function TestimonialCarousel({ slice }: Props) {
  const [current, setCurrent] = React.useState(0);
  const { length } = slice.items;

  const goToPrevious = () => {
    if (current === 0) {
      setCurrent(length - 1);
    } else {
      setCurrent(current - 1);
    }
  };

  const goToNext = () => {
    if (current === length - 1) {
      setCurrent(0);
    } else {
      setCurrent(current + 1);
    }
  };

  const multipleTestimonials = slice.items.length > 1;
  const hasHeading = slice.primary.heading && multipleTestimonials;

  return (
    <section
      className={clsx(
        styles.testimonialCarousel,
        'full-width',
        slice.primary.background === 'Gray' && 'bgGray',
      )}
    >
      <div>
        {hasHeading && slice.items && slice.items.length && (
          <div className={styles.carouselTop}>
            <h2 className={clsx(styles.heading, 'marketing-h2')}>
              {slice.primary.heading.text}
            </h2>
            {multipleTestimonials && (
              <div className={styles.buttons}>
                <Button
                  type={'Icon'}
                  onClick={goToPrevious}
                  ariaLabel={`Go to previous slide`}
                  className={styles.buttonLeft}
                >
                  <ArrowLeftIcon />
                </Button>
                <Button
                  type={'Icon'}
                  className={styles.buttonRight}
                  onClick={goToNext}
                  ariaLabel={`Go to next slide`}
                >
                  <ArrowLeftIcon />
                </Button>
              </div>
            )}
          </div>
        )}
        {slice.items.map((item, index) => {
          return (
            <article
              className={`${styles.slide} ${
                index === current && styles.slideActive
              }`}
              key={`testimonial-carousel-${index}`}
            >
              <div
                style={{
                  backgroundImage: `url("${item.image.url}")`,
                }}
                className={`${styles.image} img-full-width-mobile`}
                aria-label={item.image.alt}
              />
              <div className={styles.text}>
                <h4 className="marketing-xlarge-p font-normal">{item.quote}</h4>
                <div>
                  <small className={styles.footnote}>{item.author_name}</small>
                  <small className={styles.footnote}>{item.author_title}</small>
                </div>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
}

export const query = graphql`
  fragment PrismicMarketingTestimonialCarouselFragment on PrismicMarketingPageDataBodyTestimonialCarousel {
    id
    slice_type
    slice_label
    primary {
      heading {
        text
      }
      background
    }
    items {
      author_name
      author_title
      quote
      image {
        url
        alt
      }
    }
  }
  fragment PrismicHomepageTestimonialCarouselFragment on PrismicHomepageDataBodyTestimonialCarousel {
    id
    slice_type
    slice_label
    primary {
      heading {
        text
      }
      background
    }
    items {
      author_name
      author_title
      quote
      image {
        url
        alt
      }
    }
  }
  fragment PrismicPartnerTestimonialCarouselFragment on PrismicPartnerPageDataBodyTestimonialCarousel {
    id
    slice_type
    slice_label
    primary {
      heading {
        text
      }
      background
    }
    items {
      author_name
      author_title
      quote
      image {
        url
        alt
      }
    }
  }
`;
