import React from 'react';
import useWindowSize from '@utils/hooks/useWindowSize';
import { PrismicHomepageDataBodyTabsEmbed } from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice: PrismicHomepageDataBodyTabsEmbed;
}

const Tab = ({
  onClick,
  activeTab,
  children,
  id,
}: {
  onClick?: (a: string) => void;
  activeTab?: string;
  id: string;
  children: React.ReactNode;
}) => {
  return (
    <li
      className={`${styles.tabsListItem} ${
        activeTab === id ? styles.tabsListItemActive : ``
      }`}
      role={'tab'}
      aria-selected={activeTab === id}
    >
      <button type={'button'} onClick={() => onClick(id)}>
        {children}
      </button>
    </li>
  );
};

const TabsEmbedDesktop = ({ slice }: Props) => {
  const INITIAL_TAB = 0;
  const [activeTab, setActiveTab] = React.useState(`tab-${INITIAL_TAB}`);
  const numberClicksArray = new Array(slice.items.length).fill(0);
  const [numberClicks, setNumberClicks] = React.useState(numberClicksArray);
  const tabPanelWrapperRef = React.useRef<HTMLDivElement>();

  const resizeIframes = () => {
    if (!tabPanelWrapperRef.current) {
      return;
    }

    const width = tabPanelWrapperRef.current.offsetWidth;
    const gridXGaps = (14 + 1) * 16;
    const gridYGaps = 29 * 16;
    const height = Math.floor(
      ((((width - gridXGaps) * 56) / 92) * 29) / 14 + gridYGaps + 160 + 60,
    );
    tabPanelWrapperRef.current.style.height = `${height}px`;
  };

  React.useEffect(() => {
    resizeIframes();
  }, [activeTab]);

  React.useLayoutEffect(() => {
    resizeIframes();
  });

  const clickHandler = (index: number) => {
    setActiveTab(`tab-${index}`);

    // This logic is to 'lazy load' the hidden tab panels so
    // they load only after they're clicked.
    const clicks = [...numberClicks];
    clicks[index] += 1;
    setNumberClicks(clicks);
  };

  return (
    <div>
      <ol className={styles.tabsList} role={'tablist'}>
        {slice.items.map((item, index) => {
          const tabId = `tab-${index}`;
          return (
            <Tab
              id={tabId}
              key={`tab-label-${index}`}
              onClick={() => clickHandler(index)}
              activeTab={activeTab}
            >
              {item.tab_label}
            </Tab>
          );
        })}
      </ol>
      <div ref={tabPanelWrapperRef}>
        {slice.items.map((item, index) => (
          <div
            className={`${styles.tabsPanel} ${
              activeTab === `tab-${index}` ? styles.tabsPanelActive : ``
            }`}
            key={`tab-panel-${index}`}
            role={'tabpanel'}
            aria-hidden={activeTab !== `tab-${index}`}
          >
            <iframe
              className={styles.tabsIframe}
              title={`Tab content: ${item.tab_label}`}
              width={`100%`}
              height={`100%`}
              src={
                numberClicks[index] > 0 || index === INITIAL_TAB
                  ? item.iframe_src.url
                  : undefined
              }
              frameBorder="0"
              allow="encrypted-media"
              allowFullScreen={true}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const TabsEmbedMobile = ({ slice }: Props) => {
  return (
    <div className={`${styles.imageContainer} img-full-width-mobile`}>
      <img
        src={slice.primary.mobile_image.url}
        alt={slice.primary.mobile_image.alt}
      />
    </div>
  );
};

const TabsEmbed = ({ slice }: Props) => {
  const windowSize = useWindowSize();
  return (
    <section
      className={`${styles.tabsEmbed} bgGray full-width pad-${slice.primary.vertical_margin}`}
    >
      <h2 className="marketing-h2 text-center md:max-w-2xl md:mx-auto mb-16">
        {slice.primary.heading.text}
      </h2>

      {windowSize.width < 900 ? (
        <TabsEmbedMobile slice={slice} />
      ) : (
        <TabsEmbedDesktop slice={slice} />
      )}
    </section>
  );
};

export default TabsEmbed;
