import React from 'react';
import Button from '@components/Button';
import {
  PrismicMarketingPageDataBodyCtaBanner,
  PrismicHomepageDataBodyCtaBanner,
} from '@typings/prismic-types';
import * as styles from './styles.module.scss';

interface Props {
  slice:
    | PrismicMarketingPageDataBodyCtaBanner
    | PrismicHomepageDataBodyCtaBanner;
}

const CtaBanner: React.ReactNode = ({ slice }: Props) => {
  const primaryIcon = slice.primary.primary_cta_icon;
  const secondaryIcon = slice.primary.secondary_cta_icon;

  return (
    <section
      className={`${styles.ctaBanner} hasPaddingBottom full-width pad-${
        slice.primary.vertical_margin
      } ${slice.primary.background ? `bg${slice.primary.background}` : ``}`}
    >
      <h4 className='marketing-xlarge-p'>{slice.primary.heading.text}</h4>
      {slice.primary.primary_cta_link && (
        <div className={styles.buttons}>
          <Button
            href={slice.primary.primary_cta_link.url}
            iconType={primaryIcon}
            hasMarginRight={!!slice.primary.secondary_cta_label}
            type={'Primary'}
          >
            {slice.primary.primary_cta_label}
          </Button>
          {slice.primary.secondary_cta_label && (
            <Button
              href={slice.primary.secondary_cta_link.url}
              iconType={secondaryIcon}
              iconPosition={'Left'}
              type={'Secondary'}
            >
              {slice.primary.secondary_cta_label}
            </Button>
          )}
        </div>
      )}
    </section>
  );
};

export default CtaBanner;
