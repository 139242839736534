// extracted by mini-css-extract-plugin
export var strike = "styles-module--strike--D1JkO";
export var tabsEmbed = "styles-module--tabsEmbed--cvtsk";
export var tabsList = "styles-module--tabsList--Uiny1";
export var tabsListItem = "styles-module--tabsListItem---vsAR";
export var tabsListItemActive = "styles-module--tabsListItemActive--xAP7E";
export var tabsPanel = "styles-module--tabsPanel--e1ulb";
export var tabsPanelActive = "styles-module--tabsPanelActive--G6gAU";
export var iframeContainer = "styles-module--iframeContainer--kVnP1";
export var imageContainer = "styles-module--imageContainer--DczdA";
export var tabsIframe = "styles-module--tabsIframe--fWA+d";