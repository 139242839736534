import clsx from 'clsx';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import Countdown from 'react-countdown';
import { graphql } from 'gatsby';
import Button from '@components/Button';
import { PrismicPartnerFragmentFragment } from '@typings/prismic-types';
import * as styles from './styles.module.scss';
import MarketingRichText from '@components/MarketingRichText/MarketingRichText';

interface Props {
  slice: PrismicPartnerFragmentFragment;
}

export default function Partner({ slice }: Props) {
  const {
    main_text,
    expiration_datetime,
    text_after_expiration,
    card_customer_text,
    card_title,
    card_subtitle,
    primary_button_link,
    primary_button_text,
    secondary_button_link,
    secondary_button_text,
    fine_print,
  } = slice.primary;

  return (
    <section className={clsx(styles.partners, 'full-width')}>
      <div className={styles.main}>
        <div className={styles.logos}>
          {slice.items.map((item, i, items) => (
            <div key={i} className={styles.logo}>
              <img src={item.logos.url} alt={item.logos.alt} />
              {i < items.length - 1 && <span className={styles.plus}>+</span>}
            </div>
          ))}
        </div>
        <div className={styles.mainText}>
          <RichText render={main_text.richText} />
        </div>
        <Counter expiration={expiration_datetime}>
          {text_after_expiration && (
            <div className={styles.expirationText}>
              <RichText render={text_after_expiration.richText} />
            </div>
          )}
        </Counter>
      </div>
      <div className={styles.card}>
        <div className={styles.customerText}>{card_customer_text.text}</div>
        <h2 className={styles.cardTitle}>{card_title.text}</h2>
        <div className={styles.subtitle}>{card_subtitle.text}</div>
        <div className={styles.buttons}>
          {primary_button_link.url && (
            <Button
              className={styles.primaryButton}
              href={primary_button_link.url}
              iconType="Arrow"
            >
              {primary_button_text.text}
            </Button>
          )}
          {secondary_button_link && (
            <Button
              className={styles.secondaryButton}
              type="Secondary"
              href={secondary_button_link.url}
              iconType="Arrow"
            >
              {secondary_button_text.text}
            </Button>
          )}
        </div>
        <div className="mt-8">
          <MarketingRichText
            render={fine_print.richText}
            className="font-neue-haas-display text-xs leading-4 text-gray-700"
          />
        </div>
      </div>
    </section>
  );
}

interface CounterProps {
  expiration: string;
  children?: React.ReactNode;
}
function Counter({ expiration, children }: CounterProps) {
  if (!expiration) {
    return <>{children}</>;
  }

  return (
    <Countdown
      date={expiration}
      renderer={({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <>{children}</>;
        }
        // Render a countdown
        return (
          <div className={styles.countdown}>
            <TimePart title="Days" value={days} />
            <span className={styles.separator}>:</span>
            <TimePart title="Hours" value={hours} />
            <span className={styles.separator}>:</span>
            <TimePart title="Minutes" value={minutes} />
            <span className={styles.separator}>:</span>
            <TimePart title="Seconds" value={seconds} />
          </div>
        );
      }}
    />
  );
}

interface TimePartProps {
  title: string;
  value: number;
}
function TimePart({ title, value }: TimePartProps) {
  return (
    <div className={styles.part}>
      <span className={styles.title}>{title}</span>
      <span className={styles.value}>{value.toString().padStart(2, '0')}</span>
    </div>
  );
}

export const query = graphql`
  fragment PrismicMarketingPartnerFragment on PrismicMarketingPageDataBodyPartner {
    id
    slice_type
    slice_label
    items {
      logos {
        url
        alt
      }
    }
    primary {
      card_customer_text {
        text
      }
      card_subtitle {
        text
      }
      card_title {
        text
      }
      expiration_datetime
      text_after_expiration {
        richText
      }
      fine_print {
        richText
      }
      main_text {
        richText
      }
      primary_button_link {
        link_type
        url
        uid
      }
      primary_button_text {
        text
      }
      secondary_button_link {
        link_type
        url
        uid
      }
      secondary_button_text {
        text
      }
    }
  }
  fragment PrismicPartnerFragment on PrismicPartnerPageDataBodyPartner {
    id
    slice_type
    slice_label
    items {
      logos {
        url
        alt
      }
    }
    primary {
      card_customer_text {
        text
      }
      card_subtitle {
        text
      }
      card_title {
        text
      }
      expiration_datetime
      text_after_expiration {
        richText
      }
      fine_print {
        richText
      }
      main_text {
        richText
      }
      primary_button_link {
        link_type
        url
        uid
      }
      primary_button_text {
        text
      }
      secondary_button_link {
        link_type
        url
        uid
      }
      secondary_button_text {
        text
      }
    }
  }
`;
